<template>
  <div>
    <HeaderView :page_index="1"></HeaderView>
    <el-dialog v-model="show.login" title=""  class="login_view" width="30%"  center :show-close="false" @closed="close_view"><LoginDialog /></el-dialog>

    <el-skeleton v-if="loading" :rows="5" animated  style="text-align: left; margin: 30px 30px 30px 30px;" />
    <div v-else class="home">
      <el-container>
        <el-container>
          <el-aside width="200px">
            <ul  id="powers">
              <li v-if="power_first.name">
                <div class="heng2 border_bottom" style="height: 50px;" @click="click_tab_name(power_first.name)"><span :class="[power_first.indexPower === current_type_index ? ' power_select' : '']">{{ power_first.name }}</span> <span class="num">{{ power_first.num }}</span></div>
              </li>
              <li >
                <!-- <div :class="'heng2 border_bottom' + 0 === current_type_index ?' power_select': ''" style="height: 50px;" @click="click_tab_name('全部')"><span :class="[power_dic['全部'].indexPower === current_type_index ?'  power_select': ''">全部</span> <span class="num">{{ power_dic['全部'].num }}</span></div> -->
                <div class="border_bottom" style="height: 50px;" @click="click_tab_name('默认')"><span :class="[power_dic['默认'].indexPower  === current_type_index ? ' power_select': '' ]">默认</span> <span class="num">{{ power_dic['默认'].num }}</span></div>
              </li>
              <li >
                <!-- <div :class="'heng2 border_bottom' + 0 === current_type_index ?' power_select': ''" style="height: 50px;" @click="click_tab_name('全部')"><span :class="[power_dic['全部'].indexPower === current_type_index ?'  power_select': ''">全部</span> <span class="num">{{ power_dic['全部'].num }}</span></div> -->
                <div class="border_bottom" style="height: 50px;" @click="click_tab_name('全部')"><span :class="[power_dic['全部'].indexPower  === current_type_index ? ' power_select': '' ]">全部</span> <span class="num">{{ power_dic['全部'].num }}</span></div>
              </li>
              <!-- <li >
                <div class="heng2 border_bottom" style="height: 50px;" @click="click_tab_name('上古')"><span :class="[power_dic['东周'].indexPower === current_type_index ? ' power_select' : '']">上古</span> <span class="num">{{ power_dic['上古'].num }}</span></div>
              </li> -->
              <li >
                <div class="left">
                  <div class="border_right" style="height: 100px;" @click="click_tab_name('东周')"><span :class="[power_dic['东周'].indexPower === current_type_index ? ' power_select' : '']">东周</span> <span class="num">{{ power_dic['东周'].num }}</span></div>
                  <!-- <div class="border_right border_bottom" style="height: 100px;" @click="click_tab_name('东周')"><span class="power_select">东周</span> <span class="num">{{ power_dic['东周'].num }}</span></div> -->
                  <!-- <div class="" style="height: 20px;" @click="click_tab_name('战国')">x</div> -->
                </div>
                <div class="right">
                  <div class="border_bottom" style="height: 50px;" @click="click_tab_name('春秋')"><span :class="[power_dic['春秋'].indexPower === current_type_index ? ' power_select' : '']">春秋</span> <span class="num">{{ power_dic['春秋'].num }}</span></div>
                  <div class="" style="height: 50px;" @click="click_tab_name('战国')"><span :class="[power_dic['战国'].indexPower === current_type_index ? ' power_select' : '']">战国</span> <span class="num">{{ power_dic['战国'].num }}</span></div>
                </div>
              </li>
              <li >
                <div class="heng2 border_top" style="height: 25px;" @click="click_tab_name('秦朝')"><span :class="[power_dic['秦朝'].indexPower === current_type_index ? ' power_select' : '']">秦朝</span> <span class="num">{{ power_dic['秦朝'].num }}</span></div>
              </li>
              <li >
                <div class="heng2 border_top" style="height: 80px;" @click="click_tab_name('西汉')"><span :class="[power_dic['西汉'].indexPower === current_type_index ? ' power_select' : '']">西汉</span> <span class="num">{{ power_dic['西汉'].num }}</span></div>
              </li>
              <li >
                <div class="heng2 border_top" style="height: 25px;" @click="click_tab_name('新朝')"><span :class="[power_dic['新朝'].indexPower === current_type_index ? ' power_select' : '']">新朝</span> <span class="num">{{ power_dic['新朝'].num }}</span></div>
              </li>
              <li >
                <div class="heng2 border_top" style="height: 70px;" @click="click_tab_name('东汉')"><span :class="[power_dic['东汉'].indexPower === current_type_index ? ' power_select' : '']">东汉</span> <span class="num">{{ power_dic['东汉'].num }}</span></div>
              </li>
              <li>
                <div>
                  <div class="heng2 border_top" style="height: 40px;" @click="click_tab_name('三国')"><span :class="[power_dic['三国'].indexPower === current_type_index ? ' power_select' : '']">三国</span> <span class="num">{{ power_dic['三国'].num }}</span></div>
                </div>
                <div class="left">
                  <div class="border_bottom border_top border_right" style="height: 30px;" @click="click_tab_name('西晋')"><span :class="[power_dic['西晋'].indexPower === current_type_index ? ' power_select' : '']">西晋</span> <span class="num">{{ power_dic['西晋'].num }}</span></div>
                  <div class="border_bottom border_right" style="height: 40px;" @click="click_tab_name('东晋')"><span :class="[power_dic['东晋'].indexPower === current_type_index ? ' power_select' : '']">东晋</span> <span class="num">{{ power_dic['东晋'].num }}</span></div>
                  <!-- <div class="border_right" style="height: 9px;" @click="click_tab_name('南北朝')">x</div> -->
                  <div class="border_right" style="height: 9px;" @click="click_tab_name('南北朝')"></div>
                </div>
                <div class="right">
                  <!-- <div class="" style="height: 10px;" @click="click_tab_name('三国')">x</div> -->
                  <div class="" style="height: 10px;" @click="click_tab_name('三国')"></div>
                  <div class=" border_top border_bottom" style="height: 70px;" @click="click_tab_name('十六国')"><span :class="[power_dic['十六国'].indexPower === current_type_index ? ' power_select' : '']">十六国</span> <span class="num">{{ power_dic['十六国'].num }}</span></div>
                </div>
                <div>
                  <div class="heng2 border_bottom" style="height: 60px;" @click="click_tab_name('南北朝')"><span :class="[power_dic['南北朝'].indexPower === current_type_index ? ' power_select' : '']">南北朝</span> <span class="num">{{ power_dic['南北朝'].num }}</span></div>
                </div>
              </li>

              <li >
                <div class="heng2" style="height: 30px;" @click="click_tab_name('隋朝')"><span :class="[power_dic['隋朝'].indexPower === current_type_index ? ' power_select' : '']">隋朝</span> <span class="num">{{ power_dic['隋朝'].num }}</span></div>
              </li>
              <li >
                <div class="heng2 border_top border_bottom" style="height: 80px;" @click="click_tab_name('唐朝')"><span :class="[power_dic['唐朝'].indexPower === current_type_index ? ' power_select' : '']">唐朝</span> <span class="num">{{ power_dic['唐朝'].num }}</span></div>
              </li>
              <li>
                <div class="left">
                  <div class="border_bottom border_right" style="height: 30px;" @click="click_tab_name('五代十国')"><span :class="[power_dic['五代十国'].indexPower === current_type_index ? ' power_select' : '']">五代十国</span> <span class="num">{{ power_dic['五代十国'].num }}</span></div>
                  <div class="border_bottom border_right" style="height: 50px;" @click="click_tab_name('北宋')"><span :class="[power_dic['北宋'].indexPower === current_type_index ? ' power_select' : '']">北宋</span> <span class="num">{{ power_dic['北宋'].num }}</span></div>
                  <div class="border_bottom border_right" style="height: 50px;" @click="click_tab_name('南宋')"><span :class="[power_dic['南宋'].indexPower === current_type_index ? ' power_select' : '']">南宋</span> <span class="num">{{ power_dic['南宋'].num }}</span></div>
                </div>
                <div class="right">
                  <div class="border_bottom" style="height: 75px;" @click="click_tab_name('辽')"><span :class="[power_dic['辽'].indexPower === current_type_index ? ' power_select' : '']">辽</span> <span class="num">{{ power_dic['辽'].num }}</span></div>
                  <div class="border_bottom" style="height: 40px;" @click="click_tab_name('金')"><span :class="[power_dic['金'].indexPower === current_type_index ? ' power_select' : '']">金</span> <span class="num">{{ power_dic['金'].num }}</span></div>
                  <div class="" style="height: 15px;" @click="click_tab_name('蒙古')"><span :class="[power_dic['蒙古'].indexPower === current_type_index ? ' power_select' : '']">蒙古</span> <span class="num">{{ power_dic['蒙古'].num }}</span></div>
                </div>
              </li>
              <li >
                <div class="heng2 " style="height: 75px;" @click="click_tab_name('元朝')"><span :class="[power_dic['元朝'].indexPower === current_type_index ? ' power_select' : '']">元朝</span> <span class="num">{{ power_dic['元朝'].num }}</span></div>
                <div class="left">
                  <div class="border_bottom" style="height: 15px;" @click="click_tab_name('元朝')">北元</div>
                </div>
                <div class="right">
                  <!-- <div class="border_top border_left" style="height: 15px;" @click="click_tab_name('明朝')">x</div> -->
                  <div class="border_top border_left" style="height: 15px;" @click="click_tab_name('明朝')"></div>
                </div>
              </li>
              <li >
                <div class="heng2 " style="height: 80px;" @click="click_tab_name('明朝')"><span :class="[power_dic['明朝'].indexPower === current_type_index ? ' power_select' : '']">明朝</span> <span class="num">{{ power_dic['明朝'].num }}</span></div>
                <div class="left">
                  <div class="border_bottom" style="height: 15px;" @click="click_tab_name('明朝')">南明</div>
                </div>
                <div class="right">
                  <div class="border_top border_left" style="height: 15px;" @click="click_tab_name('清朝')">后金</div>
                </div>
              </li>
              <li >
                <div class="heng2" style="height: 80px;" @click="click_tab_name('清朝')"><span :class="[power_dic['清朝'].indexPower === current_type_index ? ' power_select' : '']">清朝</span> <span class="num">{{ power_dic['清朝'].num }}</span></div>
              </li>
            </ul>
          </el-aside>
          <el-container>
            <el-skeleton v-if="loading3" :rows="5" animated  style="text-align: left; margin: 30px 30px 30px 30px;" />
            <el-main  v-else  style="text-align: left; margin: 20px 20px 30px 20px;" >
              <OneRank :rank_info="info"  v-for="(info, index) in list" :key="index" />
              <div class="footer">
                <el-pagination v-if="type_list[current_type_index].page!==0" @current-change="get_list" :current-page="current_page"  layout="prev, pager, next" :page-count="type_list[current_type_index].page"></el-pagination>
              </div>
            </el-main>
          </el-container>
        </el-container>
      </el-container>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import Utils from '@/utils/utils'
import axios from 'axios'
import OneRank from '@/components/items/OneRank'
import HeaderView from '@/components/other/HeaderView'
import LoginDialog from '@/components/other/LoginDialog'

export default {
  name: 'Rank_index',
  mounted () {
    this.get_index()
  },
  components: {
    OneRank, HeaderView, LoginDialog
  },
  data () {
    return {
      loading: true,
      loading2: false,
      loading3: false,
      is_long: false,
      niming: store.state.niming,
      base_img: store.state.base_img,
      type_list: [],
      list: [],
      current_type_index: 0,
      current_group_index: 0,
      current_page: 1,
      zici_paixu: 'index',
      group_tag: 'my',
      person_state: 1,
      see_want: 'net',
      power_dic: {},
      power_first: {},
      show: {}
    }
  },
  methods: {
    get_index () {
      const query = this.$route.query
      const powerId = query.power
      console.log(query)

      axios.post(Utils.check_is_niming() ? '/rank_index_unlogin/' : '/rank_index/', { power_id: powerId })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.list = res.data.data.list
          this.type_list = res.data.data.type_list
          // var powerDic = {}
          // for (var i = 0; i < this.type_list.length; i++) {
          //   var power = this.type_list[i]
          //   power.indexPower = i
          //   powerDic[this.type_list[i].name] = power
          // }
          // this.power_dic = powerDic
          // console.log(powerDic)
          var powerDic = {}
          for (var i = 0; i < this.type_list.length; i++) {
            var power = this.type_list[i]
            power.indexPower = i
            powerDic[this.type_list[i].name] = power
            if (powerId && this.type_list[i].id === powerId) {
              this.current_type_index = i
            }
          }
          this.power_dic = powerDic
          // console.log(powerDic)
          if (['全部', '默认', '上古', '东周', '春秋', '战国', '秦朝', '西汉', '新朝', '东汉', '三国', '西晋', '东晋', '十六国', '南北朝', '隋朝', '唐朝', '五代十国', '北宋', '南宋', '辽', '金', '蒙古', '元朝', '明朝', '清朝'].indexOf(this.type_list[0].name) === -1) {
            this.power_first = this.type_list[0]
            console.log(this.power_first)
          }
          console.log(this.current_type_index)

          this.loading = false
        })
    },
    click_tab_name (name) {
      console.log(name)
      console.log(this.power_dic[name])
      this.click_tab(this.power_dic[name].indexPower)
    },
    get_list  (e) {
      if (e > 1 && Utils.check_is_niming()) {
        this.loading3 = false
        this.show.login = true
        return
      }
      this.current_page = e
      this.loading3 = true
      axios.post(Utils.check_is_niming() ? '/rank_list_unlogin/' : '/rank_list/', { type: this.type_list[this.current_type_index].value, page: this.current_page })
        .then(res => {
          this.loading2 = false
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.list = res.data.data.list
          this.loading3 = false
          window.scrollTo(0, 0)
        })
    },
    click_tab (e) {
      if (this.current_type_index !== e) {
        this.current_type_index = e
        this.get_list(1)
      }
    }
  }
}
</script>
<style scoped>
.tab2{
  display: block;
  text-align: left;
  padding: 30px 40px 30px 20px;
}
.tab2 .el-tag,.tab2  .el-dropdown{
  vertical-align: middle;
}
.to_sw{
  color: #fff;
  background: #67c23a;
  padding: 16px 0px;
  width: 160px;
  margin: 20px 0px 20px 40px;
  cursor:pointer;
}

.mx-1{
  margin: 0px 5px;
  cursor:pointer;
}
ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}
li{
  background: lightblue;
}
li div.border_bottom{
  border-bottom:  1px solid #999;
}
li div.border_top{
  border-top:  1px solid #999;
}
li div.border_right{
  border-right:  1px solid #999;
}
li div.border_left{
  border-left:  1px solid #999;
}
li div{
  /* vertical-align: middle; */
  /* display:table-cell; */
  /* border: 1px solid pink; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;

}
li div.left,li div.right{
  display: inline-block;
  width: 98px;
  vertical-align: top;
}
li .heng1{
  /* display: inline-block; */
  /* width: 80px; */
}
li .heng2{
  width: 200px;
}
ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}
li{
  background: lightblue;
}
li div.border_bottom{
  border-bottom:  1px solid rgba(0, 0, 0, 0.08);
}
li div.border_top{
  border-top:  1px solid rgba(0, 0, 0, 0.08);
}
li div.border_right{
  border-right:  1px solid rgba(0, 0, 0, 0.08);
}
li div.border_left{
  border-left:  1px solid rgba(0, 0, 0, 0.08);
}
li div{
  /* vertical-align: middle; */
  /* display:table-cell; */
  /* border: 1px solid pink; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* cursor:pointer; */
}
li div.left,li div.right{
  display: inline-block;
  width: 98px;
  vertical-align: top;
}
li .heng1{
  /* display: inline-block; */
  /* width: 80px; */
}
li .heng2{
  width: 200px;
}
/* li div span.power_select{
  color:#ffd04b;
  font-size: 20px;
} */
#powers{
  font-size: 14px;
}
#powers  span.power_select{
  color:#50b7c1;
}
#powers  .power_select{
  color:#50b7c1;
}
/* #powers  span.name{
  color:#ffd04b;
  font-size: 20px;
} */
li .num{
  color: rgba(0, 0, 0, 0.32);
  font-size: 12px;
  display: inline-block;
  margin-left: 4px;
}
.to_sw{
  color: #fff;
  background: #67c23a;
  padding: 16px 0px;
  width: 160px;
  margin: 20px 0px 20px 40px;
  cursor:pointer;
}

.mx-1{
  margin: 0px 5px;
  cursor:pointer;
}
</style>
