<template>
<router-link target="_blank" :to="{ name: 'Rank_detail', params: { id: info.id }}">
  <div class="img_window_big" >
    <div class="" >
      <span class="title"> {{info.title}}</span>
      <div class="num_div">
        <span style="color:#c4c2c2;">(</span>
        <span style="color:#c4c2c2;">{{info.num}}</span>
        <span style="color:#c4c2c2;">)</span>
      </div>
    </div>
  </div>
</router-link>
</template>
<script>
// import router from '@/router/index.js'
import Utils from '@/utils/utils'
import Clipboard from 'clipboard'
import { isEditing } from '@/components/config.js'

export default {
  name: 'OneRank',
  props: {
    rank_info: Map
  },
  data () {
    return {
      info: this.rank_info,
      is_editing: isEditing
    }
  },
  methods: {
    // to_show_more () {
    //   this.show_more = true
    // },
    copy_word (e) {
      Utils.alert_success('已复制到剪切板')
      const clipboard = new Clipboard('.want_copy_word')
      clipboard.on('success', (e) => {
        // 释放内存
        clipboard.destroy()
      })
    }
    // ,
    // go_to (e) {
    //   // const routeData = this.$router.resolve({ name: 'Rank_detail', params: { id: this.info.id } })
    //   const routeData = this.$router.resolve({ name: this.is_editing ? 'Rank_detail_edit' : 'Rank_detail', params: { id: this.info.id } })
    //   window.open(routeData.href, '_blank')
    // }
  }
}
</script>

<style scoped>
.img_window_big {
  color: #999;
  margin: 0px 20px 30px 0px;
  width: 290px;
  display: inline-block;
  cursor:pointer;
}
.title{
  color: #50b7c1;
  font-weight: bold;
  /* line-height: 20px; */
  vertical-align: middle;

}
.num_div{
  display: inline-block;
  margin-left: 3px;
  font-size: 12px;
  vertical-align: middle;
  /* line-height: 20px; */
}
.num_div span{
  vertical-align: middle;
}
</style>
